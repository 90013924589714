import { BASE_URL_OFFERS } from '../../utils/common';
import {
	DataWithState,
	LoadingState,
	LogoutCallbackData,
	UnauthorizedCallback,
} from '../../typings/common';
import { OfferRequestData, OfferState } from '../../app/offerSlice';
import { requestHttp } from '../requestHttp';
import { OfferDTO } from '../dto';

export interface AllOffersEntity {
	limit: number;
	months: number[];
	minAmount: number;
}

export const apiOffer: {
	calculateOffer: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void,
		params: OfferRequestData
	) => Promise<DataWithState<OfferState>>;
	getOffers: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void
	) => Promise<DataWithState<AllOffersEntity>>;
} = {
	calculateOffer: (unauthorizedCallback, logoutCallback, params) => {
		return requestHttp(
			`${BASE_URL_OFFERS}/v1/CASH/terms`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback,
			{
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Something went wrong.');
			})
			.then((dto: OfferState) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: {
						...params,
						monthlyPayment: 0,
					},
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
	getOffers: (unauthorizedCallback, logoutCallback) => {
		return requestHttp(
			`${BASE_URL_OFFERS}/v1/CASH/offers`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Something went wrong.');
			})
			.then((dto: OfferDTO) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: {
						limit: 0,
						months: [],
						minAmount: 0,
					},
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
};
