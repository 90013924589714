import { BASE_URL } from '../../utils/common';
import {
	DataWithState,
	LoadingState,
	LogoutCallbackData,
	UnauthorizedCallback,
} from '../../typings/common';
import { requestHttp } from '../requestHttp';
import { ProviderEntity } from '../../typings/disbursement';

export const apiProviders: {
	getProvidersData: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void
	) => Promise<DataWithState<ProviderEntity[]>>;
} = {
	getProvidersData: (unauthorizedCallback, logoutCallback) => {
		return requestHttp(
			`${BASE_URL}/v1/disbursement/providers`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}

				throw new Error('Something went wrong.');
			})
			.then((dto: ProviderEntity[]) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: [],
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
};
