import React from 'react';
import ProfileElement from './ProfileElement';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { LogoutOptions } from '@auth0/auth0-react';

interface ProfileData {
	onLogout: (options?: LogoutOptions) => void;
}

function Profile(props: ProfileData) {
	const profileData = useSelector((state: RootState) => state.user.data);

	return (
		<div>
			<div
				style={{
					color: '#1B1F3BCC',
					fontSize: '24px',
					fontWeight: '800',
					lineHeight: '28px',
				}}
			>
				Profile
			</div>

			<ProfileElement caption={'Full name'} data={profileData?.fullName} />
			<ProfileElement caption={'Telephone number'} data={profileData?.phone} />
			<ProfileElement caption={'E-mail'} data={profileData?.email} />

			<div style={{ paddingTop: '72px' }}>
				<Button
					style={{
						textTransform: 'none',
						fontWeight: 800,
						fontSize: '15px',
						lineHeight: '24px',
						paddingLeft: '0',
					}}
					onClick={() => {
						props.onLogout({
							logoutParams: {
								returnTo: window.location.origin,
							},
						});
					}}
				>
					Log out
				</Button>
			</div>
		</div>
	);
}

export default Profile;
