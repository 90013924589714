import React from 'react';
import BlueAlert from '../../assets/blueAlert.svg';

interface WarningNotificationProps {
	msgCmp: React.ReactNode;
}

const BlueNotification = (prop: WarningNotificationProps) => {
	return (
		<div
			style={{
				background: '#70B6F61F',
				borderRadius: '12px',
				fontWeight: 500,
				fontSize: '13px',
				lineHeight: '20px',
				padding: '12px 16px',
				display: 'flex',
				alignItems: 'start',
				gap: '12px',
			}}
		>
			<img style={{ marginTop: '2px' }} src={BlueAlert} alt="" />
			{prop.msgCmp}
		</div>
	);
};

export default BlueNotification;
