import React, { useState } from 'react';
import SalmonLogo from '../../assets/salmon-logo.svg';
import CommentIcon from '../../assets/tuiIconCommentLarge.svg';
import { AppBar, Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';

const onPageEnum = {
	creditLineMain: 'credit-line-main',
	creditLineApplicationIssue: 'credit-line-application-issue',
	creditLineDisbursement: 'credit-line-disbursement',
	creditLineDetails: 'credit-line-details',
	creditLineSignContract: 'credit-line-sign-contract',
	creditLineOTP: 'credit-line-OTP',
	creditLineWellDone: 'credit-line-welldone',
	creditLineOTPIssue: 'credit-line-OTP-issue',
};

function SalmonAppBar() {
	const userName = useSelector((state: RootState) => state.user.data.fullName);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const onWhatPageTap = (amplEvent: AmplitudeEvents) => {
		let onPage: string;

		if (window.location.pathname.startsWith('/something-went-wrong')) {
			onPage = onPageEnum.creditLineApplicationIssue;
		} else if (window.location.pathname.startsWith('/transfer')) {
			onPage = onPageEnum.creditLineDisbursement;
		} else if (window.location.pathname.startsWith('/loan-details')) {
			onPage = onPageEnum.creditLineDetails;
		} else if (window.location.pathname.startsWith('/contract')) {
			onPage = onPageEnum.creditLineSignContract;
		} else if (window.location.pathname.startsWith('/sign-contract')) {
			onPage = onPageEnum.creditLineOTP;
		} else if (window.location.pathname.startsWith('/well-done')) {
			onPage = onPageEnum.creditLineWellDone;
		} else {
			onPage = onPageEnum.creditLineMain;
		}

		track(amplEvent, { on_page: onPage });
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		onWhatPageTap(AmplitudeEvents.Profile_Tap);
	};

	const navigate = useNavigate();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onOpenFacebook = () => {
		const link = document.createElement('a');
		link.href = 'https://m.me/salmonphilippines';
		link.target = '_blank';
		link.click();

		onWhatPageTap(AmplitudeEvents.Chat_Tap);
	};

	const userNameWithoutExtraSpaces = userName ? userName.replace(/\s+/g, ' ').trim() : '';
	const nameForLogo = userNameWithoutExtraSpaces
		? `${userNameWithoutExtraSpaces.split(' ')[0][0]}${userNameWithoutExtraSpaces.split(' ')[1][0]}`
		: ''; // todo check by ?

	return (
		<AppBar
			color={'transparent'}
			position="static"
			style={{ height: '56px', boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)' }}
		>
			<div
				style={{
					padding: '0 12px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					height: '100%',
				}}
			>
				<div style={{ flexGrow: 0 }}>
					<Link
						onClick={() => {
							onWhatPageTap(AmplitudeEvents.Salmon_Tap);
						}}
						to={'/'}
					>
						<img
							style={{
								position: 'relative',
								width: '100 %',
								height: '100 %',
							}}
							src={SalmonLogo}
							alt="logo"
						/>
					</Link>
				</div>
				<div style={{ display: 'flex' }}>
					<IconButton
						size="large"
						aria-label="account of current user"
						onClick={onOpenFacebook}
						color="inherit"
					>
						<img
							style={{
								position: 'relative',
								width: '100 %',
								height: '100 %',
							}}
							src={CommentIcon}
							alt="logo"
						/>
					</IconButton>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						onClick={handleMenu}
						color="inherit"
					>
						<Avatar
							sx={{
								bgcolor: '#1B1F3BA6',
								fontSize: '13px',
								width: '32px',
								height: '32px',
							}}
						>
							{nameForLogo}
						</Avatar>
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<div style={{ width: '240px' }}>
							<MenuItem
								onClick={() => {
									navigate('/profile');
									handleClose();
								}}
							>
								Profile
							</MenuItem>
						</div>
					</Menu>
				</div>
			</div>
		</AppBar>
	);
}

export default SalmonAppBar;
