import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import SalmonAppBar from '../../components/SalmonAppBar/SalmonAppBar';
import NotFound from '../NotFound/NotFound';
import Profile from '../Profile/Profile';
import { useAuth0 } from '@auth0/auth0-react';
import ProvideADestinationAccount from '../ProvideADestinationAccount/ProvideADestinationAccount';
import ReviewYourLoanTerms from '../ReviewYourLoanTerms/ReviewYourLoanTerms';
import CashLoanContract from '../CashLoanContract/CashLoanContract';
import OtpForm from '../OtpForm/OtpForm';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../app/userSlice';
import { AppDispatch } from '../../app/store';
import SomethingWentWrong from '../SomethingWentWrong/SomethingWentWrong';
import EntryPointPage from '../EntryPointPage/EntryPointPage';
import NotificationScreen from '../NotificationScreen/NotificationScreen';
import * as Sentry from '@sentry/react';

function RoutePage() {
	const { logout, getAccessTokenSilently } = useAuth0();

	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(fetchUser({ getAccessTokenSilently, logout }));
		// eslint-disable-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

	return (
		<div>
			<SalmonAppBar />
			<div
				style={{
					maxWidth: '360px',
					margin: 'auto',
					padding: '16px',
					color: 'rgba(27, 31, 59, 0.8)',
				}}
			>
				<SentryRoutes>
					<Route path={'/'} element={<EntryPointPage />} />
					<Route path={'/profile'} element={<Profile onLogout={logout} />} />
					<Route path={'/transfer'} element={<ProvideADestinationAccount />} />
					<Route path={'/loan-details'} element={<ReviewYourLoanTerms />} />
					<Route path={'/contract'} element={<CashLoanContract />} />
					<Route path={'/sign-contract'} element={<OtpForm />} />
					<Route path={'/well-done'} element={<NotificationScreen />} />
					<Route path={'/something-went-wrong'} element={<SomethingWentWrong />} />
					<Route path="*" element={<NotFound />} />
				</SentryRoutes>
			</div>
		</div>
	);
}

export default RoutePage;
