import {
	DataWithState,
	LoadingState,
	LogoutCallbackData,
	UnauthorizedCallback,
} from '../../typings/common';
import { BASE_URL } from '../../utils/common';
import { requestHttp } from '../requestHttp';

export enum PermittedApplicationStatus {
	LOAN_ISSUE = 'LOAN_ISSUE',
	DOCUMENT_SIGNED = 'DOCUMENT_SIGNED',
	PROCESS = 'PROCESS',
}

export interface ApplicationCreateEntity {
	amount: number;
	months: number;
	providerId: string;
	providerAccount: string;
	providerType: string;
}

export interface ApplicationCreatedEntity {
	applicationNumber: string;
	contractUrl: string;
}

export interface ApplicationConfirmationEntity {
	applicationNumber: string;
}

export interface ApplicationConfirmedEntity {
	confirmationId: string;
}

export interface ApplicationTicketEntity {
	confirmationTicketId: string;
	code: string;
}

export interface ApplicationTicketCreatedEntity {
	confirmationTicketId: string;
}

export interface ApplicationGetEntity {
	applicationNumber: string;
	applicationStatus: PermittedApplicationStatus;
}

export const apiApplications: {
	applicationCreate: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void,
		data: ApplicationCreateEntity
	) => Promise<DataWithState<ApplicationCreatedEntity>>;
	applicationConfirmation: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void,
		data: ApplicationConfirmationEntity
	) => Promise<DataWithState<ApplicationConfirmedEntity>>;
	applicationFinal: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void,
		data: ApplicationTicketEntity
	) => Promise<DataWithState<ApplicationTicketCreatedEntity>>;
	applicationGet: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void
	) => Promise<DataWithState<Array<ApplicationGetEntity>>>;
} = {
	applicationCreate: (unauthorizedCallback, logoutCallback, data) => {
		return requestHttp(
			`${BASE_URL}/v1/applications/cash`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback,
			{
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}

				throw new Error('Something went wrong.');
			})
			.then((dto: ApplicationCreatedEntity) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: {
						applicationNumber: '',
						contractUrl: '',
					},
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
	applicationFinal: (unauthorizedCallback, logoutCallback, data) => {
		return requestHttp(
			`${BASE_URL}/v1/applications/cash/confirmations/complete`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback,
			{
				method: 'POST',
				body: JSON.stringify({
					code: data.code,
					confirmationId: data.confirmationTicketId,
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}

				throw new Error('Something went wrong.');
			})
			.then((dto: ApplicationTicketCreatedEntity) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: {
						confirmationTicketId: '',
					},
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
	applicationConfirmation: (unauthorizedCallback, logoutCallback, data) => {
		return requestHttp(
			`${BASE_URL}/v1/applications/cash/confirmations/start`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback,
			{
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}

				throw new Error('Something went wrong.');
			})
			.then((dto: ApplicationConfirmedEntity) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: {
						confirmationId: '',
					},
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
	applicationGet: (unauthorizedCallback, logoutCallback) => {
		return requestHttp(
			`${BASE_URL}/v1/applications/cash`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}

				throw new Error('Something went wrong.');
			})
			.then((dto: ApplicationGetEntity[]) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: [],
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
};
