import React, { useEffect, useState } from 'react';
import SalmonButton from '../../designSystem/SalmonButton';
import { css } from '@emotion/react';
import Timer from '../../assets/timer.svg';
import Contacts from '../../assets/contacts.svg';
import Discount from '../../assets/discount.svg';
import HowToGetBlock from './HowToGetBlock';
import NotificationScreen from '../NotificationScreen/NotificationScreen';
import Calculator from './Calculator';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';
import { socialMediaLinks } from '../../config/socialMediaLinks';
import { MediaLink } from '../../typings/common';
import { AllOffersEntity } from '../../apiService/methods/apiOffer';
import { notificationScreenConfig } from '../../config/notificationScreen';

function MainPage() {
	const [offers, setOffers] = useState<AllOffersEntity>({
		limit: 0,
		months: [],
		minAmount: 0,
	});

	useEffect(() => {
		track(AmplitudeEvents.CreditlineMain_Opened);
	}, []);

	const notEnoughLimit = () => offers.limit < offers.minAmount;

	const handleSocialMediaClick = (link: MediaLink) => {
		track(AmplitudeEvents.SocialMediaBadge_Tap, { platform: link.platform });
		window.open(link.url, '_blank');
	};

	if (notEnoughLimit()) {
		return (
			<NotificationScreen
				title={notificationScreenConfig.notEnoughLimit.title}
				message={notificationScreenConfig.notEnoughLimit.message}
			/>
		);
	}

	return (
		<div>
			<h1
				style={{
					textAlign: 'center',
					marginTop: '48px',
					marginBottom: '12px',
					fontWeight: '700',
					fontSize: '32px',
				}}
			>
				Get up to ₱{Math.floor(offers.limit)}
				<br />
				with Salmon
			</h1>
			<div
				style={{
					textAlign: 'center',
					fontWeight: '600',
					fontSize: '16px',
					lineHeight: '22px',
					margin: '12px 0',
				}}
			>
				You’re pre-approved for a Cash Loan.
				<br />
				This is an exclusive offer for you
			</div>
			<div>
				<img
					style={{ borderRadius: '16px' }}
					src={'https://s.slmn.ph/web/cashloan/iphone-start.png'}
					alt="logo"
					width={'100%'}
					height={'auto'}
				/>
			</div>
			<div
				style={{
					marginTop: '24px',
				}}
			>
				<SalmonButton
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					css={css`
						font-family: Gilroy, serif;
						font-size: 16px;
						font-weight: 700;
						line-height: 24px;
						letter-spacing: 0;
						text-align: center;
					`}
					onClick={() => {
						document.querySelector('#calculator')?.scrollIntoView({
							behavior: 'smooth',
						});
						track(AmplitudeEvents.ToCalculator_Tap);
					}}
					variant={'contained'}
					fullWidth
				>
					Get Cash Loan
				</SalmonButton>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '60px',
					marginBottom: '16px',
				}}
			>
				<img src={Timer} alt="logo" width={'40px'} height={'40px'} />
			</div>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontWeight: '700',
						fontSize: '18px',
						lineHeight: '22px',
						textAlign: 'center',
					}}
				>
					Get Cash Loan in 3 minutes
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '22px',
						marginTop: '8px',
						textAlign: 'center',
					}}
				>
					Transferred instantly to your <br />
					e-wallet or bank account
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '32px',
					marginBottom: '16px',
				}}
			>
				<img src={Contacts} alt="logo" width={'40px'} height={'40px'} />
			</div>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontWeight: '700',
						fontSize: '18px',
						lineHeight: '22px',
						textAlign: 'center',
					}}
				>
					No additional
					<br />
					documents needed
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '22px',
						marginTop: '8px',
						textAlign: 'center',
					}}
				>
					This special offer is exclusive to
					<br />
					you and is non-transferrable
				</div>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '32px',
					marginBottom: '16px',
				}}
			>
				<img src={Discount} alt="logo" width={'40px'} height={'40px'} />
			</div>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontWeight: '700',
						fontSize: '18px',
						lineHeight: '22px',
						textAlign: 'center',
					}}
				>
					Affordable monthly payments
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						fontWeight: '500',
						fontSize: '16px',
						lineHeight: '22px',
						marginTop: '8px',
						textAlign: 'center',
					}}
				>
					Small interest rate, no hidden charges
				</div>
			</div>

			<h5
				style={{
					fontFamily: 'Nantes',
					fontStyle: 'normal',
					fontWeight: 700,
					fontSize: '28px',
					lineHeight: '28px',
					textAlign: 'center',
					marginTop: '56px',
					marginBottom: '24px',
				}}
			>
				3 easy steps to apply
			</h5>

			<div>
				<HowToGetBlock />
			</div>

			<h5
				id={'calculator'}
				style={{
					fontFamily: 'Nantes',
					fontStyle: 'normal',
					fontWeight: 700,
					fontSize: '28px',
					lineHeight: '28px',
					textAlign: 'center',
					marginTop: '56px',
					marginBottom: '24px',
				}}
			>
				Loan terms
			</h5>
			<div
				style={{
					textAlign: 'center',
					fontWeight: '600',
					fontSize: '16px',
					lineHeight: '22px',
					margin: '24px 0',
				}}
			>
				Choose your preferred loan amount
				<br />
				and payment period
			</div>

			<Calculator setOffers={setOffers} offers={offers} />

			<div
				style={{
					fontWeight: 500,
					fontSize: '14px',
					lineHeight: '20px',
					marginTop: '82px',
				}}
			>
				© 2023 Salmon. All rights reserved
			</div>

			<div
				style={{
					marginTop: '28px',
					gap: '40px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				{socialMediaLinks.map((socialMediaLink: MediaLink) => (
					<div
						onClick={() => handleSocialMediaClick(socialMediaLink)}
						key={socialMediaLink.name}
						style={{ cursor: 'pointer' }}
					>
						<img
							src={socialMediaLink.icon}
							alt={socialMediaLink.name}
							aria-label={socialMediaLink.name}
							width={'auto'}
							height={'24px'}
						/>
					</div>
				))}
			</div>

			<div
				style={{
					fontWeight: 500,
					fontSize: '12px',
					lineHeight: '18px',
					marginTop: '28px',
					color: '#828282',
				}}
			>
				"Salmon" is operated by Fintech Holdings Ltd which offers platform-as-a-service to the
				following Salmon partners: 1) Sunprime Finance Inc., SEC Reg. No. CS201916698, C.A. No. 1241
				(Online Lending Platform); 2) FHL Financing Company, Inc., SEC Reg. No. 2022100071291-07,
				C.A. F-22-0038-29 (Service App only); Read the terms & conditions before availing any loan
				from Salmon partners.
			</div>
		</div>
	);
}

export default MainPage;
