import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from './userSlice';
import { offerSlice } from './offerSlice';
import { transferSlice } from './transferSlice';

export const store = configureStore({
	reducer: {
		user: userSlice.reducer,
		offer: offerSlice.reducer,
		transfer: transferSlice.reducer,
	},
	devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
