import React from 'react';
import { useNavigate } from 'react-router-dom';
import SalmonButton from '../../designSystem/SalmonButton';

function NotFound() {
	const navigate = useNavigate();

	const goToMainPage = () => {
		navigate('/');
	};

	return (
		<div>
			<div
				style={{
					fontWeight: 800,
					fontSize: '24px',
					lineHeight: '28px',
					margin: '24px 0 16px 0',
					textAlign: 'center',
				}}
			>
				Page not found
			</div>

			<SalmonButton
				variant="contained"
				fullWidth
				onClick={goToMainPage}
				style={{
					fontWeight: '800',
				}}
			>
				Go to main page
			</SalmonButton>
		</div>
	);
}

export default NotFound;
