export interface ApiAccountData {
	loans: ApiLoanData[];
}

export interface UpcomingPaymentData {
	amount: number;
	dueDate: string;
}

export interface NextPaymentData {
	base: number;
	amount: number;
	debt: number;
	dueDate: string;
	fee: number;
	paid: number;
	upcoming?: UpcomingPaymentData;
}

export interface ApiLoanData {
	loanId: number;
	loanNumber: string;
	nextPayment?: NextPaymentData;
	status: AccountStatuses;
	loanType: LoanTypes;
	progress: {
		missed: number;
		paid: number;
		total: number;
	};
}

export enum LoanTypes {
	POS = 'POS',
	CASH = 'CASH',
}

export enum AccountStatuses {
	CLOSED = 'CLOSED',
	ACTIVE = 'ACTIVE',
	OVERDUE = 'OVERDUE',
}
