import React from 'react';

interface ProfileElementData {
	caption: string | undefined;
	data: string | undefined;
}

function ProfileElement(props: ProfileElementData | null) {
	return (
		<div style={{ padding: '20px 0' }}>
			<div
				style={{
					color: '#1B1F3B66',
					fontSize: '13px',
					fontWeight: '500',
					lineHeight: '20px',
				}}
			>
				{props?.caption}
			</div>
			<div
				style={{
					color: '#1B1F3BCC',
					fontSize: '15px',
					fontWeight: '800',
					lineHeight: '24px',
				}}
			>
				{props?.data}
			</div>
		</div>
	);
}

export default ProfileElement;
