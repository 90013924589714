import React, { useEffect } from 'react';
import SalmonButton from '../../designSystem/SalmonButton';
import AlertWrong from '../../assets/alert-something-went-wrong.svg';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';

const SomethingWentWrong = () => {
	useEffect(() => {
		track(AmplitudeEvents.SomethingWrong_Opened);
	}, []);

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					margin: '64px 0 24px 0',
				}}
			>
				<img src={AlertWrong} width={'96px'} height={'96px'} alt={'alert'} />
			</div>

			<h5
				style={{
					fontFamily: 'Nantes',
					fontStyle: 'normal',
					fontWeight: 700,
					fontSize: '28px',
					lineHeight: '28px',
					marginTop: '16px',
					marginBottom: '8px',
					textAlign: 'center',
				}}
			>
				Something went wrong
			</h5>

			<div
				style={{
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '22px',
					marginBottom: '24px',
					textAlign: 'center',
				}}
			>
				A technical issue stopped us from completing the transfer. Contact Salmon Customer Care on
				Messenger for the next steps
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<SalmonButton
					variant={'contained'}
					style={{
						marginTop: '12px',
						marginBottom: '32px',
						fontWeight: 600,
						fontSize: '17px',
						lineHeight: '24px',
					}}
					onClick={() => {
						track(AmplitudeEvents.GoToMessenger_Tap);
						window.open('https://m.me/salmonphilippines', '_blank');
					}}
				>
					Go to Messenger
				</SalmonButton>
			</div>

			<div
				style={{
					fontFamily: 'Gilroy',
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '14px',
					lineHeight: '20px',
					textAlign: 'center',
					color: '#828282',
				}}
			>
				If the chat isn’t opening, find us using <br />
				@SalmonPhilippines
			</div>
		</div>
	);
};

export default SomethingWentWrong;
