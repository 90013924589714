export interface ProviderEntity {
	id: string;
	code: string;
	name: string;
	type: ProviderType.E_WALLET;
	fee: number;
	feeCurrency: string;
	minAmount: number;
	maxAmount: number;
}

export enum ProviderType {
	'E_WALLET' = 'E_WALLET',
	BANK = 'BANK',
}
