export const getPesoFromSentimoAndFormat = (sentimo: number | undefined | null) => {
	if (sentimo === null || sentimo === undefined) {
		return sentimo;
	}
	const peso = sentimo / 100;
	return peso.toLocaleString('en-US', { minimumFractionDigits: 2 });
};

export const getFormattedNumberInPeso = (peso: number | undefined | null, digits = 0) => {
	if (peso === null || peso === undefined) {
		return peso;
	}
	return peso.toLocaleString('en-US', { minimumFractionDigits: digits });
};
