export interface DataWithState<T> {
	data: T;
	loading: LoadingState;
	error?: string;
}

export enum LoadingState {
	IDLE = 'idle',
	PENDING = 'pending',
	SUCCEEDED = 'succeeded',
	FAILED = 'failed',
}

declare global {
	interface Window {
		accessToken: string;
		salmonsession_id: string;
	}
}

export interface AuthProps {
	getAccessTokenSilently: () => Promise<string>;
	logout: (data: LogoutCallbackData) => void;
}

export interface MediaLink {
	name: string;
	platform: string;
	url: string;
	icon: string;
}

export interface UrlConfig {
	[key: string]: string;
}

export interface LogoutCallbackData {
	logoutParams: {
		returnTo: string;
	};
}

export type UnauthorizedCallback = () => Promise<string>;
