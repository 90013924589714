import React from 'react';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import SalmonButton from '../../designSystem/SalmonButton';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';

const HowToGetBlock = () => {
	return (
		<div>
			<Stepper sx={{}} orientation="vertical">
				<Step active>
					<StepLabel>
						<div
							style={{
								fontStyle: 'normal',
								fontWeight: 700,
								fontSize: '16px',
								lineHeight: '22px',
							}}
						>
							Choose how much you need
						</div>
					</StepLabel>
					<StepContent>
						<div
							style={{
								fontStyle: 'normal',
								fontWeight: 500,
								fontSize: '14px',
								lineHeight: '26px',
							}}
						>
							Choose a loan amount and payment period you’re comfortable with to estimate your
							monthly payment
							<br />
							<SalmonButton
								fullWidth
								variant={'contained'}
								onClick={() => {
									document.querySelector('#calculator')?.scrollIntoView({
										behavior: 'smooth',
									});
									track(AmplitudeEvents.GoToCalculator_Tap);
								}}
								style={{
									backgroundColor: '#FFECE7',
									color: '#F05A5A',
									fontWeight: '600',
									fontSize: '14px',
									lineHeight: '20px',
									marginTop: '16px',
								}}
							>
								Choose your loan terms
							</SalmonButton>
						</div>
					</StepContent>
				</Step>
				<Step active>
					<StepLabel>
						<div
							style={{
								fontStyle: 'normal',
								fontWeight: 700,
								fontSize: '16px',
								lineHeight: '22px',
							}}
						>
							Decide how to receive your loan
						</div>
					</StepLabel>
					<StepContent>
						<div
							style={{
								fontStyle: 'normal',
								fontWeight: 500,
								fontSize: '14px',
								lineHeight: '26px',
							}}
						>
							We can transfer your money to an e-wallet or bank account that you own
						</div>
					</StepContent>
				</Step>
				<Step active>
					<StepLabel>
						<div
							style={{
								fontStyle: 'normal',
								fontWeight: 700,
								fontSize: '16px',
								lineHeight: '22px',
							}}
						>
							Sign the contract
						</div>
					</StepLabel>
					<StepContent>
						<div
							style={{
								fontStyle: 'normal',
								fontWeight: 500,
								fontSize: '14px',
								lineHeight: '26px',
							}}
						>
							Read the loan agreement and sign it via OTP so we can transfer your money
						</div>
					</StepContent>
				</Step>
			</Stepper>
		</div>
	);
};

export default HowToGetBlock;
