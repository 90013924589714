import React, { forwardRef, useEffect, useState } from 'react';
import SalmonTextField from '../../designSystem/SalmonTextField';
import { InputAdornment, OutlinedInputProps } from '@mui/material';
import AutosuggestChannel from './AutosuggestChannel';
import SalmonButton from '../../designSystem/SalmonButton';
import { useNavigate } from 'react-router-dom';
import Lock from '../../assets/lock.svg';
import { NumericFormatProps, PatternFormat } from 'react-number-format';
import { ProviderEntity, ProviderType } from '../../typings/disbursement';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { setTransferData } from '../../app/transferSlice';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
	function NumericFormatCustom(props, ref) {
		const { onChange, ...other } = props;

		return (
			<PatternFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				format={'+63 ### ### ####'}
				allowEmptyFormatting
				valueIsNumericString
			/>
		);
	}
);

const ProvideADestinationAccount = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [channelValue, setValue] = useState<ProviderEntity | null>(null);
	const [accountValue, setAccountValue] = useState<string>('');
	const [validation, setValidation] = useState({
		provider: '',
		account: '',
	});

	const isEwallet = channelValue?.type === ProviderType.E_WALLET;

	useEffect(() => {
		track(AmplitudeEvents.CreditLineDisbursement_Opened);
	}, []);

	const userData = useSelector((state: RootState) => state.user.data);
	const transferData = useSelector((state: RootState) => state.transfer.data);

	useEffect(() => {
		if (transferData.providerAccount) {
			// eslint-disable-next-line
			transferData.providerType === ProviderType.E_WALLET
				? setAccountValue(transferData.providerAccount.replace('+63', ''))
				: setAccountValue(transferData.providerAccount);
		}
		if (transferData.providerId) {
			// todo change store to save full data about provider data
			setValue({
				id: transferData.providerId,
				name: transferData.providerName,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				type: transferData.providerType as ProviderType,
				fee: 0,
				code: '',
				feeCurrency: '',
				maxAmount: 0,
				minAmount: 0,
			});
		}
	}, []);

	const onChangeChannelValue = (newValue: ProviderEntity | null) => {
		setValidation({
			...validation,
			provider: '',
		});
		setValue(newValue);
		setAccountValue('');
	};
	const onContinue = () => {
		const accountIsValid = isEwallet ? accountValue.length === 10 : accountValue.length > 0;

		if (channelValue?.id && accountIsValid) {
			dispatch(
				setTransferData({
					providerId: channelValue?.id,
					providerAccount: isEwallet ? '+63' + accountValue : accountValue,
					providerType: channelValue?.type,
					providerName: channelValue?.name,
				})
			);

			navigate('/loan-details');
		} else {
			setValidation({
				provider: channelValue?.id ? '' : 'Select an option',
				account: isEwallet ? 'Invalid phone number' : 'Enter an account number',
			});
		}
	};

	return (
		<div>
			<h5
				style={{
					fontFamily: 'Nantes',
					fontStyle: 'normal',
					fontWeight: 700,
					fontSize: '28px',
					lineHeight: '28px',
					marginTop: '16px',
					marginBottom: '12px',
				}}
			>
				Where do you want to receive your money?
			</h5>

			<div
				style={{
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '22px',
					marginBottom: '16px',
				}}
			>
				This is where we’ll transfer your loan. Make sure to provide correct account information
			</div>
			{/*<WarningNotification*/}
			{/*    msgCmp={'Carefully specify the data for the payout. Please note you can transfer money only to yourself'}/>*/}

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
					marginBottom: '24px',
					marginTop: '24px',
				}}
			>
				<AutosuggestChannel
					error={validation.provider.length > 0}
					helperText={validation.provider || 'Loans can only be transferred to accounts you own'}
					onChangeChannelValue={onChangeChannelValue}
					channelValue={channelValue}
				/>

				<SalmonTextField
					variant={'filled'}
					focused={isEwallet}
					label={isEwallet ? 'Mobile number' : 'Account number'}
					error={validation.account.length > 0}
					helperText={validation.account}
					value={accountValue}
					onClick={() => {
						track(AmplitudeEvents.AccountNumber_Tap);
					}}
					onChange={(event) => {
						setValidation({
							...validation,
							account: '',
						});
						setAccountValue(event.target.value);
					}}
					InputProps={
						{
							disableUnderline: true,
							inputComponent: isEwallet ? (NumericFormatCustom as unknown) : null,
						} as Partial<OutlinedInputProps>
					}
				/>

				<div
					style={{
						fontWeight: 500,
						fontSize: '13px',
						lineHeight: '20px',
						display: 'flex',
						flexDirection: 'column',
						color: '#828282',
					}}
				>
					<SalmonTextField
						value={userData.fullName}
						variant={'filled'}
						label={'Name'}
						disabled
						onClick={() => {
							track(AmplitudeEvents.Name_Tap);
						}}
						InputProps={
							{
								disableUnderline: true,
								endAdornment: (
									<InputAdornment position="end">
										<img src={Lock} width={'20px'} height={'24px'} alt={'lock'} />
									</InputAdornment>
								),
							} as Partial<OutlinedInputProps>
						}
					/>
					<div
						style={{
							marginTop: '4px',
						}}
					>
						Can’t be edited. This loan is exclusive to you
					</div>
				</div>
			</div>

			<SalmonButton
				variant="contained"
				fullWidth
				onClick={() => {
					track(AmplitudeEvents.CreditlineDisbursementContinue_Tap);
					onContinue();
				}}
			>
				Continue
			</SalmonButton>
		</div>
	);
};

export default ProvideADestinationAccount;
