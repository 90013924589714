import { createSlice } from '@reduxjs/toolkit';
import { DataWithState, LoadingState } from '../typings/common';

export interface TransferState {
	providerId: string;
	providerAccount: string;
	providerType: string;
	providerName: string;
}

const initialState: DataWithState<TransferState> = {
	data: {
		providerId: '',
		providerAccount: '',
		providerType: '',
		providerName: '',
	},
	loading: LoadingState.IDLE,
};

export const transferSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setTransferData: (state, action: { payload: TransferState }) => {
			state.data = action.payload;
		},
	},
});

export const { setTransferData } = transferSlice.actions;

export default transferSlice.reducer;
