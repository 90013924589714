import React, { useEffect, useState } from 'react';
import RoutePage from './pages/RoutePage/RoutePage';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from './hooks/Auth';
import * as amplitude from '@amplitude/analytics-browser';
import { REACT_APP_AMPLITUDE_API_KEY } from './utils/common';
import { parseJwt } from './utils/parseJwt';
import { v4 } from 'uuid';

function App() {
	const { isLoading, isAuthenticated, error, loginWithRedirect, getAccessTokenSilently, logout } =
		useAuth0();

	const [accessToken, setAccessToken] = useState('');

	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			getAccessTokenSilently()
				.then((token) => {
					setAccessToken(token);
					const parsedToken = parseJwt(token);
					amplitude.init(REACT_APP_AMPLITUDE_API_KEY, parsedToken.iid, {
						minIdLength: 1,
						defaultTracking: {
							sessions: true,
						},
					});

					const uuid = v4();

					const identifyEvent = new amplitude.Identify();
					identifyEvent.set('salmonsession_id', uuid);
					amplitude.identify(identifyEvent);

					window.accessToken = token;
					window.salmonsession_id = uuid;
				})
				.catch((err: Error) => {
					logout({
						logoutParams: {
							returnTo: window.location.origin,
						},
					});
					throw new Error('Unauthorized', err);
				});
		}
	}, [isAuthenticated, isLoading, getAccessTokenSilently]);

	useEffect(() => {
		if (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
		} else if (!isAuthenticated && !isLoading) {
			loginWithRedirect()
				// eslint-disable-next-line no-console
				.then(() => console.log('redirected to login'))
				// eslint-disable-next-line no-console
				.catch((err: Error) => console.warn('Something went wrong:', err));
		}
	}, [isAuthenticated, isLoading, loginWithRedirect, error]);

	if (error) {
		return <div>Oops... {error.message}</div>;
	}

	if (isAuthenticated && !isLoading && accessToken) {
		return (
			<AuthContext.Provider value={accessToken}>
				<RoutePage />
			</AuthContext.Provider>
		);
	} else {
		return <LoadingScreen />;
	}
}

export default App;
