import React, { useEffect, useState } from 'react';
import { Autocomplete, InputAdornment, MenuItem, OutlinedInputProps } from '@mui/material';
import SalmonTextField from '../../designSystem/SalmonTextField';
import { ProviderEntity } from '../../typings/disbursement';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';
import { track } from '@amplitude/analytics-browser';
import { apiProviders } from '../../apiService/methods/apiProviders';
import { useAuth0 } from '@auth0/auth0-react';
import SalmonCdnIcon from '../../components/SalmonCdnIcon/SalmonCdnIcon';

interface AutosuggestChannelProps {
	onChangeChannelValue: (newValue: ProviderEntity | null) => void;
	channelValue: ProviderEntity | null;
	error: boolean;
	helperText: string;
}

const AutosuggestChannel = (props: AutosuggestChannelProps) => {
	// todo replace to global storage
	// const [validation, setValidation] = React.useState({channel: ''});
	const { onChangeChannelValue, channelValue, helperText, error } = props;
	const { getAccessTokenSilently, logout } = useAuth0();

	const [providerOptions, setProviderOptions] = useState<ProviderEntity[]>([]);

	useEffect(() => {
		apiProviders.getProvidersData(getAccessTokenSilently, logout).then((res) => {
			setProviderOptions(res.data);
		});
	}, []);

	return (
		<div>
			<Autocomplete
				value={channelValue}
				onChange={(e, value) => {
					onChangeChannelValue(value);
					track(AmplitudeEvents.SuggestedChannel_Tap, {
						channel_name: value?.name,
					});
				}}
				onInputChange={(e, value) => {
					const list = providerOptions
						.filter((o) => o.name.toLowerCase().includes(value.toLowerCase()))
						.map((o) => o.name);
					track(AmplitudeEvents.SelectChannelSearch_Tap, {
						search_query: value,
						suggested_list: list,
					});
				}}
				onBlur={() => {
					track(AmplitudeEvents.SelectChannel_Opened);
				}}
				options={providerOptions.map((o) => o) || []}
				renderOption={(renderProps, option) => {
					return (
						<MenuItem {...renderProps} key={option.id} value={option.id}>
							<div style={{ display: 'flex', alignItems: 'center', margin: '4px' }}>
								<SalmonCdnIcon code={option.code} type={option.type} height={32} width={32} />
								<div style={{ margin: '4px 0 4px 12px' }}>
									<div
										style={{
											fontWeight: '500',
											fontSize: '15px',
											lineHeight: '24px',
										}}
									>
										{option.name}
									</div>
								</div>
							</div>
						</MenuItem>
					);
				}}
				filterOptions={(options, state) => {
					return options.filter((o) =>
						o.name.toLowerCase().includes(state.inputValue.toLowerCase())
					);
				}}
				getOptionLabel={(option) => {
					return option.name;
				}}
				isOptionEqualToValue={(option, value) => {
					return option.id === value.id;
				}}
				renderInput={(params) => (
					<SalmonTextField
						{...params}
						error={error}
						helperText={helperText}
						variant="filled"
						placeholder={'Search...'}
						label={'Type of account'}
						InputProps={
							{
								...params.InputProps,
								disableUnderline: true,
								startAdornment: (
									<InputAdornment position="end">
										{channelValue && (
											<SalmonCdnIcon
												code={channelValue?.code}
												type={channelValue?.type}
												height={20}
												width={20}
											/>
										)}
									</InputAdornment>
								),
							} as Partial<OutlinedInputProps>
						}
					/>
				)}
			/>
		</div>
	);
};

export default AutosuggestChannel;
