import React, { useEffect, useState } from 'react';
import MainPage from '../MainPage/MainPage';
import { useAuth0 } from '@auth0/auth0-react';
import {
	apiApplications,
	ApplicationGetEntity,
	PermittedApplicationStatus,
} from '../../apiService/methods/application';
import NotificationScreen from '../NotificationScreen/NotificationScreen';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { apiAccount } from '../../apiService/methods/account';
import { AccountStatuses, ApiLoanData, LoanTypes } from '../../apiService/model/account';
import { notificationScreenConfig } from '../../config/notificationScreen';
import styled from '@emotion/styled';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 85vh;
`;

const EntryPointPage = () => {
	const [applicationStatuses, setApplicationStatuses] = useState<ApplicationGetEntity[]>();
	const [activeLoans, setActiveLoans] = useState<ApiLoanData[]>();
	const [loading, setLoading] = useState(true);
	const { getAccessTokenSilently, logout } = useAuth0();

	const getApplicationStatuses = async () => {
		await apiApplications
			.applicationGet(getAccessTokenSilently, logout)
			.then(({ data }) => setApplicationStatuses(data));
	};

	const getActiveLoans = async () => {
		await apiAccount.getAccount(getAccessTokenSilently, logout).then(({ data }) => {
			const filteredLoans = data.loans.filter(
				(loan) => loan.status !== AccountStatuses.CLOSED && loan.loanType === LoanTypes.CASH
			);
			setActiveLoans(filteredLoans);
		});
	};

	useEffect(() => {
		Promise.all([getActiveLoans(), getApplicationStatuses()]).finally(() => setLoading(false));
	}, []);

	const hasDocumentBeenSigned = () =>
		applicationStatuses?.some(
			({ applicationStatus }) => applicationStatus === PermittedApplicationStatus.DOCUMENT_SIGNED
		);

	if (hasDocumentBeenSigned()) {
		return (
			<NotificationScreen
				title={notificationScreenConfig.documentSigned.title}
				message={notificationScreenConfig.documentSigned.message}
			/>
		);
	}

	if (activeLoans?.length) {
		return (
			<NotificationScreen
				title={notificationScreenConfig.activeCashLoan.title}
				message={notificationScreenConfig.activeCashLoan.message}
			/>
		);
	}

	return loading ? (
		<Container>
			<LoadingScreen />
		</Container>
	) : (
		<MainPage />
	);
};

export default EntryPointPage;
