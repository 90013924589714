export const bankAccountNumberFormatter = (value: string) => {
	return value
		.replace(/\D/g, '')
		.replace(/(.{4})/g, '$1 ')
		.trim();
};

export const phoneNumberFormatter = (value: string) => {
	return value
		.replace(/(.{9})/, '$1 ')
		.replace(/(.{6})/, '$1 ')
		.replace(/(.{3})/, '$1 ');
};
