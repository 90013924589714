import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import SalmonTextField from '../../designSystem/SalmonTextField';
import { Link, OutlinedInputProps, Paper, Popover } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BlueNotification from '../../designSystem/Notification/BlueNotification';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { apiApplications } from '../../apiService/methods/application';
import CircularProgress from '@mui/material/CircularProgress';
import SalmonButton from '../../designSystem/SalmonButton';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';
import { useAuth0 } from '@auth0/auth0-react';

interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
	function NumericFormatCustom(props, ref) {
		const { onChange, ...other } = props;

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				maxLength={6}
				decimalScale={0}
				thousandSeparator={''}
				valueIsNumericString
			/>
		);
	}
);

const OtpForm = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { getAccessTokenSilently, logout } = useAuth0();
	const applicationNumber = searchParams.get('applicationNumber');
	const userData = useSelector((state: RootState) => state.user.data);
	const [confirmationId, setConfirmationId] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [counter, setCounter] = useState(60);

	useEffect(() => {
		track(AmplitudeEvents.CreditlineOTP_Opened);
	}, []);

	useEffect(() => {
		const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return () => clearInterval(timer);
	}, [counter]);

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		track(AmplitudeEvents.NoOTP_Tap);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [errorOtp, setErrorOtp] = useState<string>('');

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	useEffect(() => {
		if (!applicationNumber) {
			navigate('/something-went-wrong');
		}
	}, [applicationNumber, navigate]);

	const sendOtp = useCallback(() => {
		apiApplications
			.applicationConfirmation(getAccessTokenSilently, logout, {
				applicationNumber: applicationNumber as string,
			})
			.then((res) => {
				setConfirmationId(res.data?.confirmationId);
				if (res.error) {
					navigate('/something-went-wrong');
				}
			});
	}, [applicationNumber, getAccessTokenSilently, logout, navigate]);

	useEffect(() => {
		sendOtp();
	}, [sendOtp]);

	return (
		<div>
			{loading && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100vh',
						width: '100vw',
						position: 'fixed',
						opacity: 0.84,
						backgroundColor: 'white',
						zIndex: 1000,
						left: 0,
					}}
				>
					<div style={{ opacity: 1 }}>
						<CircularProgress />
					</div>
				</div>
			)}

			<h5
				style={{
					fontFamily: 'Nantes',
					fontStyle: 'normal',
					fontWeight: 700,
					fontSize: '28px',
					lineHeight: '28px',
					marginTop: '16px',
					marginBottom: '12px',
				}}
			>
				Sign contract
			</h5>

			<div
				style={{
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '22px',
					marginBottom: '16px',
				}}
			>
				Enter the OTP we sent to +63 XXX XXXX {userData?.phone?.slice(-3)} to sign
			</div>
			<BlueNotification msgCmp={'We’ve sent your copy of the contract to your email address'} />

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
					marginBottom: '16px',
					marginTop: '24px',
				}}
			>
				<SalmonTextField
					variant={'filled'}
					label={'6-digit OTP'}
					error={errorOtp?.length > 0}
					helperText={errorOtp}
					onClick={() => {
						track(AmplitudeEvents.OTP_Tap);
					}}
					onChange={(event) => {
						if (event.target.value.length === 6) {
							setLoading(true);
							apiApplications
								.applicationFinal(getAccessTokenSilently, logout, {
									confirmationTicketId: confirmationId as string,
									code: event.target.value,
								})
								.then((res) => {
									setLoading(false);

									if (!res?.error) {
										navigate('/well-done');
									} else {
										if (res?.error) {
											setErrorOtp('Check the format and try again');
										} else {
											setErrorOtp('');
										}
									}
								});
						}
					}}
					InputProps={
						{
							disableUnderline: true,
							inputComponent: NumericFormatCustom as unknown,
						} as Partial<OutlinedInputProps>
					}
				/>
			</div>

			<SalmonButton
				style={{
					color: '#F05A5A',
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '22px',
					cursor: 'pointer',
					padding: '0',

					opacity: counter > 0 ? '0.35' : '1',
				}}
				onClick={() => {
					track(AmplitudeEvents.RequestNewOTP_Tap);
					if (counter <= 0) {
						sendOtp();
						setCounter(60);
					}
				}}
			>
				Request a new OTP ({counter} seconds)
			</SalmonButton>

			<SalmonButton
				aria-describedby={id}
				onClick={handleClick}
				style={{
					color: '#F05A5A',
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '22px',
					cursor: 'pointer',
					padding: '0',
				}}
			>
				<div>Didn’t get an OTP?</div>
			</SalmonButton>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Paper
					elevation={1}
					style={{
						padding: '16px',
						fontWeight: '500',
						fontSize: '15px',
						lineHeight: '24px',
						color: 'rgba(27, 31, 59, 0.8)',
						maxWidth: '300px',
					}}
				>
					Possible reasons:
					<br />
					1. The mobile number doesn’t exist or was entered in the wrong format
					<br />
					2. The SIM card for the mobile number is installed on a different device
					<br />
					3. Weak signal and other network problems
					<br />
					Need help?&nbsp;
					<Link underline={'none'} target={'_blank'} href={'https://m.me/salmonphilippines'}>
						Contact Salmon Support
					</Link>
				</Paper>
			</Popover>
		</div>
	);
};

export default OtpForm;
