import React from 'react';
import styled from '@emotion/styled';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';

const Container = styled.section`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const InnerContainer = styled.div`
	color: #302e2e;
`;

const Title = styled.h3`
	font-family: 'Nantes', serif;
	margin: 0 0 8px;
	font-size: 28px;
	line-height: 1;
	font-weight: 700;
	color: inherit;
`;

const Text = styled.p`
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.1px;
	line-height: 22px;
	margin: 0;
`;

const Card = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 24px;
	color: #302e2e;
	border-radius: 20px;
	background-color: #f4f4f3;
	min-height: 360px;
	background-image: url('https://s.slmn.ph/web/cashloan/iphone-end.png');
	background-size: 180px;
	background-repeat: no-repeat;
	background-position: bottom;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0%;
		width: 100%;
		height: 50%;
		background: linear-gradient(
			0deg,
			#f4f4f3 0%,
			rgba(244, 244, 243, 0.8) 33.79%,
			rgba(244, 244, 243, 0) 100%
		);
	}
`;

const DownloadAppText = styled.h4`
	font-family: 'Gilroy', serif !important;
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	margin: 0;
	letter-spacing: 0.1px;
	color: inherit;
	text-align: center;
`;

const DownloadActionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1;
`;

const DownloadAppButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	border-radius: 6px;
	outline: none;
	padding: 0;

	&:focus-visible {
		border-color: #a6a6a6;
	}
`;

const DownloadAppIcon = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
`;

interface Props {
	title?: string;
	message?: string;
}

const NotificationScreen = ({ title, message }: Props) => {
	return (
		<Container>
			<InnerContainer>
				<Title>{title || 'We got your application!'}</Title>
				<Text>
					{message ||
						'You’ll be updated via SMS and email when the money is transferred into your account'}
				</Text>
			</InnerContainer>
			<Card>
				<DownloadAppText>Download Salmon app and easily manage your purchases</DownloadAppText>
				<DownloadActionContainer>
					<DownloadAppButton>
						<DownloadAppIcon
							alt="google play market icon"
							src="https://s.slmn.ph/web/cashloan/google-play.svg"
							onClick={() => {
								track(AmplitudeEvents.DownLoadApp_Tap, { store: 'google_play' });
								window.open(
									'https://play.google.com/store/apps/details?id=com.fhl.salmon',
									'_blank'
								);
							}}
						/>
					</DownloadAppButton>
					<DownloadAppButton>
						<DownloadAppIcon
							alt="app sotre market icon"
							src="https://s.slmn.ph/web/cashloan/app-store.svg"
							onClick={() => {
								track(AmplitudeEvents.DownLoadApp_Tap, { store: 'app_store' });
								window.open('https://apps.apple.com/ph/app/salmon/id6446115147', '_blank');
							}}
						/>
					</DownloadAppButton>
				</DownloadActionContainer>
			</Card>
		</Container>
	);
};

export default NotificationScreen;
