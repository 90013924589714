export enum AmplitudeEvents {
	Chat_Tap = 'Chat_Tap',
	Profile_Tap = 'Profile_Tap',
	Salmon_Tap = 'Salmon_Tap',
	CreditlineMain_Opened = 'CreditlineMain_Opened',
	ToCalculator_Tap = 'ToCalculator_Tap',
	GoToCalculator_Tap = 'GoToCalculator_Tap',
	InstallmentAmount_Drag = 'InstallmentAmount_Drag',
	InstallmentPeriod_Tap = 'InstallmentPeriod_Tap',
	GetLoanOnline_Tap = 'GetLoanOnline_Tap',
	SocialMediaBadge_Tap = 'SocialMediaBadge_Tap',
	CreditLineApplicationIssue_Opened = 'CreditLineApplicationIssue_Opened',
	CreditLineDisbursement_Opened = 'CreditLineDisbursement_Opened',
	SelectChannel_Opened = 'SelectChannel_Opened',
	SelectChannelSearch_Tap = 'SelectChannelSearch_Tap',
	SuggestedChannel_Tap = 'SuggestedChannel_Tap',
	AccountNumber_Tap = 'AccountNumber_Tap',
	Name_Tap = 'Name_Tap',
	CreditlineDisbursementContinue_Tap = 'CreditlineDisbursementContinue_Tap',
	CreditLineDetails_Opened = 'CreditLineDetails_Opened',
	CreditLineDetailsContinue_Tap = 'CreditLineDetailsContinue_Tap',
	SignContract_Opened = 'SignContract_Opened',
	SalmonLoanAgreement_Tap = 'SalmonLoanAgreement_Tap',
	IHaveRead_Tap = 'IHaveRead_Tap',
	ProceedToSign_Tap = 'ProceedToSign_Tap',
	CreditlineOTP_Opened = 'CreditlineOTP_Opened',
	OTP_Tap = 'OTP_Tap',
	RequestNewOTP_Tap = 'RequestNewOTP_Tap',
	NoOTP_Tap = 'NoOTP_Tap',
	WellDone_Opened = 'WellDone_Opened',
	DownLoadApp_Tap = 'DownLoadApp_Tap',
	SomethingWrong_Opened = 'SomethingWrong_Opened',
	GoToMessenger_Tap = 'GoToMessenger_Tap',
}
