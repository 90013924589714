import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import {
	BrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import { AUDIENCE_URL, AUTH_URL, AUTH_CLIENT_ID, SENTRY_DSN } from './utils/common';
import { store } from './app/store';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { isProd } from './utils/environment';
import { Provider } from 'react-redux';

Sentry.init({
	dsn: SENTRY_DSN,
	// environment: `prod`, // disabled because it is not showing in gitlab
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
	],
	enabled: isProd(),
	// release: "v1.1.1" // todo send app version
});

const root = createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
	palette: {
		primary: {
			main: '#EA5347',
		},
		secondary: {
			main: '#F4F4F3',
		},
	},
	typography: {
		allVariants: {
			color: 'rgba(27, 31, 59, 0.8)',
		},
		fontFamily: [
			'Gilroy',
			'Segoe UI',
			'Roboto',
			'Oxygen',
			'Ubuntu',
			'Cantarell',
			'Fira Sans',
			'Droid Sans',
			'Helvetica Neue',
		].join(','),
	},
	components: {
		MuiStepLabel: {
			styleOverrides: {
				root: {
					'& .MuiSvgIcon-root': {
						color: '#EDECEC !important',
					},
					'& .MuiStepIcon-text': {
						color: '#828282 !important',
						fill: '#828282 !important',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
					'&:hover': {
						boxShadow: 'none',
					},
				},
			},
		},
	},
});

root.render(
	<BrowserRouter>
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<Auth0Provider
					domain={AUTH_URL}
					clientId={AUTH_CLIENT_ID}
					authorizationParams={{
						redirect_uri: window.location.origin,
						audience: AUDIENCE_URL,
						scope: 'offline_access pos-client pos-client-unverified',
					}}
				>
					<App />
				</Auth0Provider>
			</Provider>
		</ThemeProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
