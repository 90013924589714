import { BASE_URL } from '../../utils/common';
import {
	DataWithState,
	LoadingState,
	LogoutCallbackData,
	UnauthorizedCallback,
} from '../../typings/common';
import { requestHttp } from '../requestHttp';
import { ApiAccountData } from '../model/account';

export const apiAccount: {
	getAccount: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void
	) => Promise<DataWithState<ApiAccountData>>;
} = {
	getAccount: (unauthorizedCallback, logoutCallback) => {
		return requestHttp(
			`${BASE_URL}/v1/accounts`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}

				throw new Error('Something went wrong.');
			})
			.then((dto: ApiAccountData) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: { loans: [] },
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
};
