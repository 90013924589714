import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { getFormattedNumberInPeso } from '../../utils/getPesoFromSentimoAndFormat';
import SalmonButton from '../../designSystem/SalmonButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { apiApplications, ApplicationCreateEntity } from '../../apiService/methods/application';
import { Alert, Snackbar } from '@mui/material';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';
import { track } from '@amplitude/analytics-browser';
import { ProviderType } from '../../typings/disbursement';
import { bankAccountNumberFormatter, phoneNumberFormatter } from '../../utils/formatters';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth0 } from '@auth0/auth0-react';

const SubTitle = styled('div')`
	font-family: 'Gilroy', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	color: #302e2e;
`;

const Caption = styled('div')`
	display: flex;
	justify-content: space-between;
	margin: 13px 0;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
`;

const Label = styled('div')`
	font-family: 'Gilroy', serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: #302e2e;
`;

const Value = styled('div')`
	color: #302e2e;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
`;

function ReviewYourLoanTerms() {
	const navigate = useNavigate();
	const { getAccessTokenSilently, logout } = useAuth0();

	useEffect(() => {
		track(AmplitudeEvents.CreditLineDetails_Opened);
	}, []);

	const calculatorData = useSelector((state: RootState) => state.offer.data);
	const transferData = useSelector((state: RootState) => state.transfer.data);
	const userDate = useSelector((state: RootState) => state.user.data);
	const [loadingApplicationCreate, setLoadingApplicationCreate] = useState(false);

	useEffect(() => {
		if (!transferData.providerId || !transferData.providerAccount) {
			// navigate('/')
		}
		// eslint-disable-line react-hooks/exhaustive-deps
	}, [navigate]);

	const [error, setError] = useState<string | null>(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const createApplication = () => {
		const applicationData: ApplicationCreateEntity = {
			amount: calculatorData.amount * 100,
			months: calculatorData.months,
			providerId: transferData.providerId,
			providerAccount: transferData.providerAccount,
			providerType: transferData.providerType,
		};

		setLoadingApplicationCreate(true);

		apiApplications
			.applicationCreate(getAccessTokenSilently, logout, applicationData)
			.then((response) => {
				if (!response.error) {
					navigate({
						pathname: '/contract',
						search: `?applicationNumber=${response.data.applicationNumber}&contractUrl=${response.data.contractUrl}`,
					});
				} else {
					setError(response.error);
					setSnackbarOpen(true);
					navigate('/something-went-wrong');
				}
				setLoadingApplicationCreate(false);
			})
			// eslint-disable-next-line no-console
			.catch((err: Error) => console.log(err));
	};

	return (
		<div>
			{error && (
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={snackbarOpen}
					autoHideDuration={6000}
					onClose={() => setSnackbarOpen(false)}
				>
					<Alert severity="error">{error}</Alert>
				</Snackbar>
			)}
			<h5
				style={{
					fontFamily: 'Nantes',
					fontStyle: 'normal',
					fontWeight: 700,
					fontSize: '28px',
					lineHeight: '28px',
					marginTop: '16px',
					marginBottom: '12px',
				}}
			>
				Review your loan terms
			</h5>

			<div
				style={{
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '22px',
					marginBottom: '36px',
				}}
			>
				Make sure everything is correct. We’ll show your contract for signing on the next step
			</div>

			<SubTitle>Terms</SubTitle>
			<div>
				<Caption>
					<Label>Loan amount</Label>
					<Value>₱{getFormattedNumberInPeso(calculatorData.amount)}</Value>
				</Caption>

				<Caption>
					<Label>Payment period</Label>
					<Value>{calculatorData.months} months</Value>
				</Caption>

				<Caption>
					<Label>Monthly payment</Label>
					<Value>₱{getFormattedNumberInPeso(calculatorData.monthlyPayment)}</Value>
				</Caption>
			</div>

			<SubTitle style={{ marginTop: '53px' }}>Where you’ll receive your money</SubTitle>
			<div>
				<Caption>
					<Label>Type of account</Label>
					<Value>{transferData.providerName}</Value>
				</Caption>

				<Caption>
					<Label>
						{transferData.providerType === ProviderType.E_WALLET
							? 'Mobile number'
							: 'Account number'}
					</Label>
					<Value>
						{transferData.providerType === ProviderType.E_WALLET
							? phoneNumberFormatter(transferData.providerAccount)
							: bankAccountNumberFormatter(transferData.providerAccount)}
					</Value>
				</Caption>

				<Caption>
					<Label>Account name</Label>
					<Value>{userDate.fullName}</Value>
				</Caption>
			</div>

			<SalmonButton
				variant={'contained'}
				style={{
					marginTop: '41px',
					marginBottom: '54px',
				}}
				fullWidth
				onClick={() => {
					if (!loadingApplicationCreate) {
						track(AmplitudeEvents.CreditLineDetailsContinue_Tap);
						createApplication();
					}
				}}
			>
				{loadingApplicationCreate ? <CircularProgress color={'inherit'} /> : 'Continue'}
			</SalmonButton>
		</div>
	);
}

export default ReviewYourLoanTerms;
