import { LogoutCallbackData, UnauthorizedCallback } from '../typings/common';

export interface FetchOptions {
	method?: string;
	body?: string;
	headers?: {
		'Content-Type': string;
	};
}

export const requestHttp = (
	url: string,
	accessToken: string,
	unauthorizedCallback: UnauthorizedCallback,
	logoutCallback: (data: LogoutCallbackData) => void,
	options?: FetchOptions,
	isFirstCall = true
): Promise<Response> => {
	return fetch(url, {
		...options,
		cache: 'no-cache',
		headers: {
			...options?.headers,
			Authorization: 'Bearer ' + accessToken,
			'x-session-id': window.salmonsession_id,
			'X-App-Name': 'loanonline',
			'X-Device-Type': 'web',
		},
	}).then((response) => {
		if (response.status === 401) {
			if (isFirstCall) {
				return unauthorizedCallback()
					.then((token: string) => {
						window.accessToken = token;
						return requestHttp(url, token, unauthorizedCallback, logoutCallback, options, false);
					})
					.catch((err: Error) => {
						logoutCallback({
							logoutParams: {
								returnTo: window.location.origin,
							},
						});
						throw new Error('Unauthorized', err);
					});
			} else {
				logoutCallback({
					logoutParams: {
						returnTo: window.location.origin,
					},
				});
				throw new Error('Unauthorized');
			}
		} else {
			return response;
		}
	});
};
