import React, { useEffect } from 'react';
import { Divider, Slider } from '@mui/material';
import { getFormattedNumberInPeso } from '../../utils/getPesoFromSentimoAndFormat';
import SalmonButton from '../../designSystem/SalmonButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { changeAmount, changeMonth, fetchOffer } from '../../app/offerSlice';
import { AllOffersEntity, apiOffer } from '../../apiService/methods/apiOffer';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';
import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';

interface Props {
	offers: AllOffersEntity;
	setOffers: (offers: AllOffersEntity) => void;
}

const SumSlider = styled(Slider)`
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const Calculator = ({ offers, setOffers }: Props) => {
	const calculatorData = useSelector((state: RootState) => state.offer.data);
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const { getAccessTokenSilently, logout } = useAuth0();

	const handleChangeMonths = (months: number) => {
		dispatch(changeMonth(months));
		dispatch(
			fetchOffer({
				months,
				amount: calculatorData.amount,
				getAccessTokenSilently,
				logout,
			})
		);
	};

	const requestOffer = (months: number, amount: number) => {
		return dispatch(
			fetchOffer({
				months,
				amount,
				getAccessTokenSilently,
				logout,
			})
		);
	};

	const handleChangeAmount = (event: Event, newValue: number | number[]) => {
		if (typeof newValue === 'number') {
			dispatch(changeAmount(newValue));
		}
	};

	const handleCommittedChangeAmount = (event: Event, newValue: number | number[]) => {
		if (typeof newValue === 'number') {
			dispatch(changeAmount(newValue));
			requestOffer(calculatorData.months, newValue);
		}
	};

	useEffect(() => {
		apiOffer.getOffers(getAccessTokenSilently, logout).then((res) => {
			setOffers(res.data);

			let months = calculatorData.months;
			// set the default month to the last month if the months is 0
			if (calculatorData.months === 0) {
				const lastMonth = res.data.months[res.data.months.length - 1];

				if (lastMonth !== undefined) {
					// set the last month as the default month
					dispatch(changeMonth(lastMonth));
					months = lastMonth;
				}
			}

			let amount = calculatorData.amount;
			if (
				calculatorData.amount > res.data.limit ||
				calculatorData.amount === 0 ||
				calculatorData.amount < res.data.minAmount
			) {
				amount = res.data.limit;
				dispatch(changeAmount(amount));
			}

			dispatch(
				fetchOffer({
					months,
					amount,
					getAccessTokenSilently,
					logout,
				})
			);
		});
		// eslint-disable-line react-hooks/exhaustive-deps
	}, [getAccessTokenSilently, logout]);

	return (
		<div
			style={{
				border: '1px solid #E3E2E1',
				borderRadius: '20px',
				padding: '20px',
			}}
		>
			<div
				style={{
					fontWeight: '700',
					fontSize: '18px',
					lineHeight: '22px',
					marginBottom: '16px',
				}}
			>
				Loan amount
			</div>
			<div
				style={{
					fontWeight: '600',
					fontSize: '16px',
					lineHeight: '22px',
					marginBottom: '10px',
				}}
			>
				₱{getFormattedNumberInPeso(calculatorData.amount)}
			</div>
			<SumSlider
				value={calculatorData.amount}
				min={offers.minAmount}
				step={100}
				max={offers.limit}
				onChange={handleChangeAmount}
				onChangeCommitted={(event, value) => {
					handleCommittedChangeAmount(event as Event, value);
					track(AmplitudeEvents.InstallmentAmount_Drag, { amount_to: value });
				}}
				aria-labelledby="non-linear-slider"
			/>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					color: '#828282',
					fontWeight: '500',
					fontSize: '12px',
					lineHeight: '18px',
					marginBottom: '24px',
				}}
			>
				<div>₱{getFormattedNumberInPeso(offers.minAmount)}</div>
				<div>₱{getFormattedNumberInPeso(offers.limit)}</div>
			</div>

			{offers.months.length > 0 && (
				<div
					style={{
						fontWeight: '700',
						fontSize: '16px',
						lineHeight: '22px',
						marginBottom: '16px',
					}}
				>
					Payment period (in months)
				</div>
			)}

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					gap: '8px',
				}}
			>
				{offers.months.map((month) => {
					return (
						<SalmonButton
							key={month}
							style={{ height: '44px' }}
							fullWidth
							variant="contained"
							color={calculatorData.months === month ? 'primary' : 'secondary'}
							onClick={() => {
								handleChangeMonths(month);
								track(AmplitudeEvents.InstallmentPeriod_Tap, { months: month });
							}}
						>
							{month}
						</SalmonButton>
					);
				})}
			</div>

			<Divider style={{ margin: '24px 0 20px 0' }} />

			<div style={{ marginBottom: '24px' }}>
				<div
					style={{
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '20px',
						marginBottom: '4px',
					}}
				>
					Monthly payment
				</div>
				<div
					style={{
						fontWeight: '700',
						fontSize: '24px',
						lineHeight: '28px',
					}}
				>
					₱{getFormattedNumberInPeso(calculatorData.monthlyPayment)}
				</div>
			</div>

			<SalmonButton
				fullWidth
				variant="contained"
				onClick={() => {
					track(AmplitudeEvents.GetLoanOnline_Tap);
					navigate('/transfer');
				}}
			>
				Continue
			</SalmonButton>
		</div>
	);
};

export default Calculator;
