import Facebook from '../assets/facebook.svg';
import Instagram from '../assets/instagram.svg';
import { MediaLink } from '../typings/common';
import { immutableURLs } from './immutableURLs';

export const socialMediaLinks: MediaLink[] = [
	{
		name: 'Facebook',
		platform: 'facebook',
		url: immutableURLs.facebook,
		icon: Facebook,
	},
	{
		name: 'Instagram',
		platform: 'instagram',
		url: immutableURLs.instagram,
		icon: Instagram,
	},
];
