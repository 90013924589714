import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUser } from '../apiService/methods/apiUser';
import { AuthProps, DataWithState, LoadingState } from '../typings/common';

export const fetchUser = createAsyncThunk('user/fetchUser', async (prop: AuthProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { getAccessTokenSilently, logout } = prop;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	return apiUser.getUserData(getAccessTokenSilently, logout);
});

export interface UserState {
	email: string;
	firstName: string;
	fullName: string;
	phone: string;
}

const initialState: DataWithState<UserState> = {
	data: {
		email: '',
		firstName: '',
		fullName: '',
		phone: '',
	},
	loading: LoadingState.IDLE,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchUser.fulfilled, (state, action) => {
			return action.payload;
		});
	},
});

export default userSlice.reducer;
