import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthProps, DataWithState, LoadingState } from '../typings/common';
import { apiOffer } from '../apiService/methods/apiOffer';

export const fetchOffer = createAsyncThunk(
	'user/fetchOffer',
	async (data: OfferRequestData & AuthProps) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const { getAccessTokenSilently, logout, amount, months } = data;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		return apiOffer.calculateOffer(getAccessTokenSilently, logout, {
			amount,
			months,
		});
	}
);

export interface OfferRequestData {
	amount: number;
	months: number;
}
export interface OfferState {
	amount: number;
	months: number;
	monthlyPayment: number;
}

const initialState: DataWithState<OfferState> = {
	data: {
		amount: 0,
		months: 0,
		monthlyPayment: 0,
	},
	loading: LoadingState.IDLE,
};

export const offerSlice = createSlice({
	name: 'offer',
	initialState,
	reducers: {
		changeMonth: (state, month: PayloadAction<number>) => {
			state.data.months = month.payload;
		},
		changeAmount: (state, amount: PayloadAction<number>) => {
			state.data.amount = amount.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchOffer.fulfilled, (state, action) => {
			state.data.monthlyPayment = action.payload.data?.monthlyPayment;
		});
	},
});

export const { changeMonth, changeAmount } = offerSlice.actions;

export default offerSlice.reducer;
