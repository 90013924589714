export const notificationScreenConfig = {
	activeCashLoan: {
		title: 'Thank you for trusting Salmon',
		message:
			'Your money has been transferred. Don’t forget to check your payment schedule and contract on the Salmon app',
	},
	documentSigned: {
		title: 'We got your application',
		message:
			'Give us a few minutes to transfer your money. Well send an SMS to update you when its done',
	},
	notEnoughLimit: {
		title: 'Thank you for interest in Salmon',
		message:
			'Unfortunately, you cannot avail a cash loan right now. Please, wait till we send you an offer.',
	},
};
