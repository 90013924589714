import React, { SyntheticEvent } from 'react';
import styled from '@emotion/styled';
import { ProviderType } from '../../typings/disbursement';
import Bank from '../../assets/bank.svg';
import Wallet from '../../assets/ewallet.svg';
import { isDev } from '../../utils/environment';

const environmentCdnLinks = {
	DEVELOPMENT: 'https://cdn.dev.fhl.world/payment/providers/',
	PRODUCTION: 'https://s.slmn.ph/payment/providers/',
};

export interface SalmonCdnIconProps {
	code: string;
	width?: string | number;
	height?: string | number;
	alt?: string;
	type?: ProviderType;
}

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const cdnLink = isDev() ? environmentCdnLinks.DEVELOPMENT : environmentCdnLinks.PRODUCTION;

const SalmonCdnIcon = styled(({ code, alt, width, height, type }: SalmonCdnIconProps) => {
	const imgPath = `${cdnLink}${code}.png`;
	const handleImageError = ({ currentTarget }: SyntheticEvent<HTMLImageElement>) => {
		const fallbackIcon = type === ProviderType.E_WALLET ? Wallet : Bank;
		currentTarget.onerror = null;
		currentTarget.src = fallbackIcon;
	};

	return (
		<Container>
			<img
				src={imgPath}
				width={width || 'auto'}
				height={height || 'auto'}
				alt={alt || `${code} icon`}
				onError={handleImageError}
			/>
		</Container>
	);
})(() => ({
	objectFit: 'cover',
}));

export default SalmonCdnIcon;
