import { BASE_URL } from '../../utils/common';
import {
	DataWithState,
	LoadingState,
	LogoutCallbackData,
	UnauthorizedCallback,
} from '../../typings/common';
import { UserState } from '../../app/userSlice';
import { requestHttp } from '../requestHttp';
import { UserDataDTO } from '../dto';

export const apiUser: {
	getUserData: (
		unauthorizedCallback: UnauthorizedCallback,
		logoutCallback: (data: LogoutCallbackData) => void
	) => Promise<DataWithState<UserState>>;
} = {
	getUserData: (unauthorizedCallback, logoutCallback) => {
		return requestHttp(
			`${BASE_URL}/v1/user/profile`,
			window.accessToken,
			unauthorizedCallback,
			logoutCallback
		)
			.then((response: Response) => {
				if (response.ok) {
					return response.json();
				}

				throw new Error('Something went wrong.');
			})
			.then((dto: UserDataDTO) => {
				return {
					data: dto,
					loading: LoadingState.SUCCEEDED,
					error: '',
				};
			})
			.catch((err: Error) => {
				return {
					data: {
						email: '',
						firstName: '',
						fullName: '',
						phone: '',
					},
					loading: LoadingState.FAILED,
					error: err.message,
				};
			});
	},
};
